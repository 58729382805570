<!-- @format -->
<script lang="ts">
  import {
    VisioMapApp,
    type VisioMapAppConfig,
  } from "~/components/map/vg/visiomap";
  import { type StoresKey } from "~/stores";
  import { onMount } from "svelte";
  import MapApp from "~/components/map/MapApp.svelte";
  import VisioMapLayer from "~/components/map/vg/VisioMapLayer.svelte";

  export let request_store_key: StoresKey;
  export let config: VisioMapAppConfig;
  const app = new VisioMapApp(config);
  const { fullscreen } = app;
  let body: HTMLBodyElement;
  onMount(() => {
    body = document.getElementsByTagName("body")[0];
  });
  $: if (body && fullscreen) {
    if ($fullscreen) {
      body.classList.add("overflow-hidden");
    } else {
      body.classList.remove("overflow-hidden");
    }
  }
</script>

<div class="container-fluid container-fluid-max-xl">
  <div class="map-container">
    <div class="map">
      <MapApp {app} {request_store_key} map_layer={VisioMapLayer} on:* />
    </div>
  </div>
</div>

<style lang="scss">
  .map-container {
    position: relative;
    color: #000;
    display: flex;
    justify-content: center;
  }
  .map {
    width: 750px;
    height: 370px;
  }
  .map :global(.virtual-scroll-item){
    cursor: pointer;
  }
  .map :global(.virtual-scroll-item):hover{
    background-color: var(--body);
    color: #fff;
  }
</style>
